.grid-4-col {
  display: grid;
  text-align: center;
  gap: 1.5rem;
}
.grid-4-col {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media only screen and (max-width: 680px) {
  .grid-4-col {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
.grid-3-col {
  display: grid;
  text-align: center;
  gap: 1.5rem;
}
.grid-3-col {
  grid-template-columns: 1fr 1fr 1fr;
}
@media only screen and (max-width: 680px) {
  .grid-3-col {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
.grid-2-col {
  display: grid;
  text-align: center;
  gap: 1.5rem;
}
.grid-2-col {
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 680px) {
  .grid-2-col {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}


.confirmation-initial-columns-1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}
@media only screen and (max-width: 680px) {
  .confirmation-initial-columns-1 {
    grid-template-columns: none;
    grid-template-rows: 4fr 3fr;
    gap: 0.5rem;
  }
}
.confirmation-initial-columns-2 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 1rem;
}
@media only screen and (max-width: 680px) {
  .confirmation-initial-columns-2 {
    grid-template-columns: none;
    grid-template-rows: 4fr 3fr 3fr;
    gap: 0.5rem;
  }
}
.confirmation-initial-columns-3 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 1rem;
}
@media only screen and (max-width: 680px) {
  .confirmation-initial-columns-3 {
    grid-template-columns: none;
    grid-template-rows: 4fr 3fr 3fr 3fr;
    gap: 0.5rem;
  }
}