:root {
  --primary-blue: #007bff;
  --muted-text: #545b62;
  --more-muted-text: #6d6d6d;
  --light-background: #dedede;
  --body-background: #f3f3f3;
  --border-radius: 0.5rem;
  --input-border: #f7f7f7;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.greyBackdropOrNo {
  background-color: var(--body-background);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mainContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -45%);
  transform: translate(-50%, -45%);
  max-width: 1200px;
  width: 100%;
  padding: 10px;
}
.mainContainerFramed {
  position: absolute;
  top: 0px;
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,0);
  border-radius: var(--border-radius);
  border: 1px solid var(--light-background);
  padding: 15px;
  background-color: #fff;
}
.stepContainer {
  background-color: #fff;
  padding: 45px;
  box-shadow: 2px 2px 9px 0px rgba(0,0,0,0.05);
  border-radius: var(--border-radius);
}

input {
  outline: none !important;
  /* border: 1px solid var(--input-border) !important;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.15) !important; */
}
  input:focus {
    outline: none !important;
    box-shadow: 0 0 1px 2px #cee5ff !important;
  }

select {
  outline: none !important;
  /* border: 1px solid var(--input-border) !important;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.15) !important; */
}
  select:focus {
    outline: none !important;
    /* yee */
    /* border: 1px solid #fff !important;
    box-shadow: 0 0 1px 2px #cee5ff !important; */
  }
textarea {
  outline: none !important;
  /* border: 1px solid var(--input-border) !important;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.15) !important; */
  resize: none;
}
  textarea:focus {
    outline: none !important;
    /* border: 1px solid #fff !important;
    box-shadow: 0 0 1px 2px #cee5ff !important; */
  }

.stepHeader {
  margin-bottom: 2rem;
}
  .stepHeader > h5 {
    font-size: 14pt;
    color: var(--muted-text);
  }

.textLight {
  color: var(--more-muted-text);
  font-weight: 400;
  font-size: 0.95rem;
}

.mainLoader {
  position: relative;
  top: 7px;
}


.prettyBox {
  cursor: pointer;
  padding: 0.7rem;
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.06);
  transition: color 0.1s ease-in-out, border 0.1s ease-in-out, box-shadow 0.1s ease-in-out, transform 0.1s ease-out;
  text-align: center;

  display:flex;justify-content:center;align-items:center;
}
.prettyBox:hover {
  box-shadow: 0px 2px 17px 4px rgba(0,0,0,0.15);
  /* transform: scale(1.025); */
  /* box-shadow: 5px 5px 11px 4px rgba(0,0,0,0.07); */

  /* -moz-box-shadow:    inset 0 0 10px #dddddd;
  -webkit-box-shadow: inset 0 0 10px #dddddd;
  box-shadow:         inset 0 0 10px #dddddd; */
}

.prettyBox > div > h6 {
  margin-bottom: 0;
}

.prettyBox > div > p {
  color: var(--muted-text) !important;
  margin-top: 7px;
  font-size: 0.85rem;
}

.prettyBoxIcon {
  position: relative;
  top: -3px;
  font-size: 2.2rem;
}

.primaryBootstrapButton {
  cursor: pointer;
  background-color: var(--primary-blue);
  transition: opacity .1s ease-in-out;
}
.primaryBootstrapButton:hover {
  opacity: 0.9;
}
.primaryBootstrapButtonDisabled {
  background-color: var(--primary-blue);
  cursor: default;
  opacity: 0.5;
}

.secondaryBootstrapButton {
  cursor: pointer;
  background-color: #545b62;
  transition: opacity .1s ease-in-out;
}
.secondaryBootstrapButton:hover {
  opacity: 0.9;
}

.successBootstrapButton {
  cursor: pointer;
  background-color: #28a745;
  color: #FFF;
  transition: opacity .1s ease-in-out;
}
.successBootstrapButton:hover {
  opacity: 0.9;
}
.successBootstrapButtonDisabled {
  cursor: default;
  opacity: 0.6;
  background-color: #28a745;
  color: #e3e3e3;
}

.warningBootstrapButton {
  cursor: pointer;
  background-color: #ffc106;
  transition: opacity .1s ease-in-out;
}
.warningBootstrapButton:hover {
  opacity: 0.95;
}
.warningBootstrapButtonDisabled {
  cursor: default;
  background-color: #ffc106;

  opacity: 0.7;
}

.successBootstrapButton > * > svg, .successBootstrapButtonDisabled > * > svg, .primaryBootstrapButton > * > svg, .primaryBootstrapButtonDisabled > * > svg, .secondaryBootstrapButton > * > svg, .warningBootstrapButton > * > svg, .warningBootstrapButtonDisabled > * > svg {
  position: relative;
  top: -1px;
  margin: 0 8px 0 8px;
}

.leftRounded {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.rightRounded {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.allRounded {
  border-radius: var(--border-radius);
}

.backToListButton > svg {
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.errorIcon {
  position: relative;
  top: -1px;
}

.summary {
  font-size: 0.8rem !important;
  table-layout: fixed;
}
  .summary td {
    word-wrap: break-word;
  }

.label {
  position: relative;
  bottom: -6px;
  font-size: 0.8rem;
}

.formRow {
  margin-top: 0 !important;
}

.requiredSpan {
  color: #ff6e5e;
}

.technicianTrackingList > li {
  height: 30px !important;
  font-size: 0.8rem;
  padding: 0 8px 0 8px !important;
  margin: 0 !important;
  overflow: hidden;
}

.technicianTrackingList > li > div {
  position: relative;
  top: 5px;
}

.technicianTrackingList > li > div > span > svg {
  position: relative;
  top: -1px;
  margin-left: 3px;
}

.invoiceNumber {
  color: #a9adb0;
}
.badgeThing {
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  display: block;
}
.badgeThingLabel {
  color: #545b62;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: bold;
  font-size: 0.7rem;
}
.badgeThingContent {
  position: relative;
  top: -6px;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.8rem;
}

@media screen and (max-width: 385px) {
  .hideOnVerySmall {
    display: none;
  }
}

.jobStatusList > .list-group-item {
  cursor: pointer;
}
.jobStatusList > .list-group-item:hover {
  opacity: 0.7;
}

.jobStatusText {
  font-size: 0.8rem;
  font-weight: bold;
}

.jobStatusDescription {
  font-size: 0.7rem;
  color: #545b62;
}

.jobHistoryDiv {
  margin-top: 5px !important;
  font-size: 0.7rem;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left !important;
}

.jobHistoryDiv > div > .list-group-item {
  padding: 4px !important;
  margin: 0 !important;
}

.narrativeHistoryHeader {
  font-weight: bold;
  margin-bottom: 14px;
  font-size: 0.8rem;
  color: #545b62;
}

.logoImage {
  max-width: 600px;
}

p {
  font-size: 0.9rem;
}

div.lds-roller > div::after {
  color: var(--light-background);
  background-color: var(--light-background);
}

.iconColor {
  color: var(--primary-blue);
}

.fixedHeader {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 10px;
  background-color: #fff;
  width: 100%;
  box-shadow: 2px 5px 10px 1px rgb(231, 231, 231);
  z-index: 999;
}

.navContainer {
  border-top: 1px solid var(--light-background);
  margin-top: 1.5rem;
  padding: 18px 0 22px 0;
}
.navButton {
  padding: 10px 20px;
  border-radius: var(--border-radius);
  cursor: pointer;
  color: #fff;
}
.leftButton {
  float: left;
  background-color: #6d6d6d;
}
  .leftButton:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
  }
.rightButton {
  float: right;
}
  .rightButton:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  .rightButtonDisabled {
    opacity: 0.5;
    cursor: default;
  }
  .rightButtonDisabled:hover {
    box-shadow: none;
  }

.cancelIconTranslate svg, .confirmIconTranslate svg {
  transform: translate(0, -1px);
}

.fixedFooter svg {
  position: relative;
  bottom: 2px;
  right: 7px;
}

.fixedHeader h5 {
  display: inline;
}

/* only the logo image link */
.fixedHeader > a {
  margin: 0;
  padding: 0;
}

.fixedHeader img {
  max-height: 70px;
  max-width: 250px;
  padding: 10px;
  border-radius: var(--border-radius);
  margin: 0;
}
.fixedHeader img:hover {
  transform: scale(1.05)
}
.fixedHeaderLinks {
  position: absolute;
  right: 10px;
  float: right;
}

.fixedHeaderLinks svg {
  position: relative;
  top: -1px;
  right: 7px;
  margin-left: 10px;
}
.fixedHeaderLinks a {
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: var(--border-radius);
  padding: 7px;
  color: #fff;
  margin-left: 10px;
}
.serviceNameInFixedHeader {
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 15px;
}

.fixedFooter {
  position: fixed;
  background-color: #fff;
  text-align: center;
  bottom: 0;
  padding: 8px 10px 8px 10px;
  width: 100%;
  box-shadow: 2px -5px 10px 1px rgb(231, 231, 231);
}

@media only screen and (max-width: 821px) {
  .fixedHeaderLinks {
    display: none;
  }
}

@media only screen and (max-width: 680px) {
  .stepHeader {
    margin-bottom: 1rem;
  }
  .stepHeader > h5 {
    font-size: 10pt;
  }
  .stepHeader > span {
    font-size: 9pt;
  }
}



.confirmation-initial-button {
  cursor: pointer;
  padding: 0.7rem;
  border-radius: var(--border-radius);

  border: 1px solid #dbdbdb;
  transition: color 0.1s ease-in-out, border 0.1s ease-in-out, box-shadow 0.1s ease-in-out, transform 0.1s ease-out;
}
  .confirmation-initial-button:hover {
    border: 1px solid #d8d8d8;
    box-shadow: 0 1px 5px 0 #dddddd;
    transform: scale(1.05);
  }

.extra-notes-button {
  margin-top: 10px;
  font-size: 0.8rem;
  cursor: pointer;
}
  .extra-notes-button:hover {
    color: var(--muted-text);
  }

.privacyPolicy {
  text-align: center;
  font-size: 0.8rem;
  color: var(--muted-text);
  margin-top: 20px;
  cursor: pointer;
}
  .privacyPolicy:hover {
    text-decoration: underline;
  }

.customTermsCard {
  max-height: 200px;
  background-color: var(--body-background);
  padding: 1.25rem;
  border: 1px solid var(--light-background);
  border-radius: var(--border-radius);
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  font-size: 10pt;
  white-space: pre-wrap;
}

.customTermsCheckContainer {
  border-radius: var(--border-radius);
  padding: 5px 0;
  margin: 5px 0 0 0;
}
  .customTermsCheckContainer label, .customTermsCheckContainer > .form-check {
    display: inline;
  }
  .customTermsCheckContainer label {
    margin-left: 10px;
  }
